<template>
  <van-empty description="找不到页面" />
</template>

<script>
import Vue from 'vue';
import { Empty } from 'vant';

Vue.use(Empty);
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
